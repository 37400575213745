.icon-wrapper {
  width: 50px;
  height: 50px;
  display: inline-block;
  border-radius: 6rem;

  i {
    &.font-24 {
      line-height: 2;
    }
  }
}

.icon-wrapper-sm {
  width: 35px;
  height: 35px;
  display: inline-block;
  border-radius: 6rem;

  i {
    &.font-16 {
      line-height: 2;
    }
  }
}

.icon-bg-primary {
  background-color: lighten($primary, 63%);
}
.icon-bg-brand {
  background-color: lighten($brand-color, 80%);
}
.icon-bg-navy {
  background-color: lighten($navy, 63%);
}
.icon-bg-teal {
  background-color: lighten($teal, 58%);
}
.icon-bg-amber {
  background-color: lighten($amber, 49%);
}
.icon-bg-burgundy {
  background-color: lighten($burgundy, 65%);
}
