/* ==============
  Form Editor
===================*/

.mce-panel {
  border-color: darken($gray-300, 5%) !important;
  background-color: $gray-100 !important;
}
.mce-menu-item:hover,
.mce-menu-item.mce-selected,
.mce-menu-item:focus {
  background-color: $primary !important;
}
.mce-menu {
  background-color: $white !important;
}
