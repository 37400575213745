.pdf-body {
  margin: 0;
  padding: 0;
  width: 297mm !important; /* A4 width in landscape */
  height: 200mm !important; /* A4 height in landscape */
  overflow: hidden;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

@media print {
  .page-break {
    page-break-after: always;
  }
}
