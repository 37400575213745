.personal-details-editor {
  min-height: 20.4px !important;
  .mce-content-body {
    outline: none !important;
  }
  .tw-data-text {
    margin-bottom: 0px !important;
  }
}
.personal-details-editor:hover {
  cursor: text;
}
