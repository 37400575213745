.search-type .ant-select-selection-item {
  margin-top: 0px !important;
}

.ant-form-item-label > label::after {
  content: '';
}

.ant-row .ant-form-item {
  display: block;
}

.hide-label {
  label {
    visibility: hidden;
  }
}

.filter-search {
  border-radius: 35px 0px 0px 35px !important;
  border-right: unset;
}

.tiltle-select {
  .ant-select-selector {
    border-radius: 0px 35px 35px 0px !important;
    // border-radius: 35px !important;
    min-height: 50px !important;
    padding: 0.2rem 0.75rem !important;
    border-left: unset !important;
  }
}

.filter-btn {
  border-radius: 8px !important;
  height: 50;
  width: 100;
  background-color: '#E6E6E6';
}

.filter-btn:hover,
.filter-btn:active,
.filter-btn:focus {
  border-radius: 8px !important;
  border-color: inherit;
  color: inherit;
}

.filter-btn-active,
.filter-btn-active:hover,
.filter-btn-active:active,
.filter-btn-active:focus {
  border-radius: 8px !important;
  background-color: #5b626b;
  color: white !important;

  path {
    fill: white;
  }
  .action {
    transform: rotate(90deg);
  }
}

.source-select .ant-select-selector {
  border-radius: 35px !important;
  padding: 0.255rem 0.75rem !important;
  height: 100% !important;
}

.search-button {
  background-color: #2e4a79;
  color: #fff;
  padding: 0.2rem 1.5rem;
  margin-left: 5px !important;
  border-radius: 35px !important;
  height: 50px !important;
}

.ant-select-dropdown {
  width: 100px !important;
}

.search-type .ant-select-selector {
  padding: 3px 10px !important;
  border-top-right-radius: 35px !important;
  border-bottom-right-radius: 35px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

label {
  margin-bottom: 10px;
  display: block;
}

.filters-wrapper {
  margin-top: 10px;
}

.candidate-list-wrapper {
  display: flex;
  flex-direction: column;
}

// .candidate-list-wrapper div:first-child {
//   text-align: right;
// }

.edit-drawer .ant-drawer-header-title {
  flex-direction: row-reverse;
}

.edit-drawer .ant-drawer-header-title button {
  margin: 0;
}

.row-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2e4a7a;
  border-color: #2e4a7a;
}

.top-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2e4a7a;
  border-color: #fff;
}
.container-crawlies {
  .ant-select.ant-select-in-form-item {
    width: 120px !important;
  }
}

// .filter-search {
//   width: 300px !important;
// }

.ant-card-body {
  padding-bottom: 0px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  background-color: transparent !important;
}

.ant-tag-has-color {
  padding-right: 10px;
  padding-left: 10px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  max-height: 36px !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options-size-changer {
  .ant-select-selector {
    min-height: auto !important;
  }
}
.ant-pagination.ant-pagination-mini .ant-pagination-item {
  min-width: 30px;
  height: 30px;
  margin: 0;
  line-height: 26px;
  font-size: 18px;
}
