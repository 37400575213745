.btn-favourite {
  background-image: url('../../../assets/icons/icons/favourite.svg');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  min-height: 28px;
  min-width: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  border: none;
}
.btn-favourite:hover,
.btn-favourite:focus {
  background-image: url('../../../assets/icons/icons/favouriteh.svg');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  min-height: 28px;
  min-width: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.btn-unfavourite {
  background-image: url('../../../assets/icons/icons/favouriteh.svg');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  min-height: 28px;
  min-width: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.btn-unfavourite:hover,
.btn-unfavourite:focus {
  background-image: url('../../../assets/icons/icons/favourite.svg');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  min-height: 28px;
  min-width: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.btn-like {
  background-image: url('../../../assets/icons/icons/favourite.svg');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  min-height: 28px;
  min-width: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}
.btn-like:hover,
.btn-like:focus {
  background-image: url('../../../assets/icons/icons/favouriteh.svg');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  min-height: 28px;
  min-width: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.btn-approve {
  background-image: url('../../../assets/icons/icons/approve.svg');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  min-height: 28px;
  min-width: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.btn-approve:hover,
.btn-approve:focus {
  background-image: url('../../../assets/icons/icons/approveh.svg');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  min-height: 28px;
  min-width: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.btn-revert,
.btn-rollback {
  background-image: url('../../../assets/icons/icons/revert.svg');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  min-height: 28px;
  min-width: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.btn-revert:hover,
.btn-revert:focus,
.btn-rollback:hover,
.btn-rollback:focus {
  background-image: url('../../../assets/icons/icons/reverth.svg');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  min-height: 28px;
  min-width: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.btn-archive {
  background-image: url('../../../assets/icons/icons/archive.svg');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  min-height: 28px;
  min-width: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.btn-archive:hover,
.btn-archive:focus {
  background-image: url('../../../assets/icons/icons/archiveh.svg');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  min-height: 28px;
  min-width: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.btn-dislike,
.btn-reject {
  background-image: url('../../../assets/icons/icons/delete.svg');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  min-height: 28px;
  min-width: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.btn-dislike:hover,
.btn-dislike:focus,
.btn-reject:hover,
.btn-reject:focus {
  background-image: url('../../../assets/icons/icons/deleteh.svg');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  min-height: 28px;
  min-width: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.btn-edit {
  background-image: url('../../../assets/icons/icons/edit.png');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  min-height: 28px;
  min-width: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.btn-edit:hover,
.btn-edit:focus {
  background-image: url('../../../assets/icons/icons/edith.png');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  min-height: 28px;
  min-width: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}
