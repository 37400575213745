.ripple {
  display: inline-block;
  position: relative;
  overflow: hidden;
  transition: all ease-in-out 0.2s;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 25%;
    height: 100%;
    width: 50%;
    background-color: #000;
    border-radius: 30%;
    opacity: 0;
    pointer-events: none;
    transition: all ease-in-out 0.4s;
    transform: scale(5, 5);
  }
  &:active {
    &:after {
      padding: 0;
      margin: 0;
      opacity: 0.2;
      transition: 0s;
      transform: scale(0, 0);
    }
  }
}
