@import '../../../assets/scss/variables';

/* Footer */
.enlarged {
  .wrapper {
    .footer {
      left: 70px;
      -webkit-transition: all 0.15s;
      transition: all 0.15s;
    }
  }
}

.footer {
  border-top: 1px solid rgba($muted, 0.2);
  bottom: 0;
  text-align: center !important;
  padding: 19px 30px 0px 0px;
  position: absolute;
  right: 0;
  left: 240px;
  color: $muted;
  -webkit-transition: all 0.15s;
  transition: all 0.15s;
}
