//icons
@import './assets/icons/icons.css';
/* import antd to set changes */
@import '~antd/dist/antd.css';
@import './assets/scss/style';

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.ant-pagination.ant-pagination-mini
  .ant-pagination-options-size-changer
  .ant-select-selector {
  min-height: 30px !important;
  padding-top: 1px !important;
}
.ant-spin-nested-loading {
  max-height: 100% !important;
}
.ant-spin-spinning {
  max-height: 100% !important;
}

.custom-upload .ant-upload {
  width: 100% !important;
}
