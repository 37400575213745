/* ==============
  Tables
===================*/

tr {
  &.even {
    background: #ececec;
  }
  &.head {
    background: #2b3a4a;
    color: #fff;
  }
}

thead {
  background: #2b3a4a;
  color: #fff;
}

th {
  font-weight: 500;
}

.table > tbody > tr > td,
.table > tfoot > tr > td,
.table > thead > tr > td {
  padding: 15px 12px;
}

.table-hover tbody tr:hover,
.table-striped tbody tr:nth-of-type(odd),
.thead-default th {
  background-color: $gray-100;
}

.table td,
.table th {
  vertical-align: middle;
}

.table-vertical {
  td {
    vertical-align: middle;
  }
}

.table-sm {
  th,
  td {
    padding: 0.3rem !important;
  }
}

/* == Responsive Table ==*/
table.focus-on {
  tbody {
    tr.focused {
      th {
        background-color: $primary;
        color: $white;
      }
      td {
        background-color: $primary;
        color: $white;
      }
    }
  }
}

.table-rep-plugin {
  .btn-toolbar {
    display: block;
  }
  .table-responsive {
    border: none !important;
  }
  .btn-group.float-right .dropdown-menu {
    left: auto;
    right: 0;
  }
  tbody {
    th {
      font-size: 14px;
      font-weight: normal;
    }
  }
  .checkbox-row {
    padding-left: 40px;

    label {
      display: inline-block;
      padding-left: 5px;
      position: relative;
      &::before {
        -o-transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        background-color: $white;
        border-radius: 3px;
        border: 1px solid $gray-300;
        content: '';
        display: inline-block;
        height: 17px;
        left: 0;
        margin-left: -20px;
        position: absolute;
        transition: 0.3s ease-in-out;
        width: 17px;
        outline: none !important;
      }
      &::after {
        color: $gray-200;
        display: inline-block;
        font-size: 11px;
        height: 16px;
        left: 0;
        margin-left: -20px;
        padding-left: 3px;
        padding-top: 1px;
        position: absolute;
        top: -1px;
        width: 16px;
      }
    }
    input[type='checkbox'] {
      cursor: pointer;
      opacity: 0;
      z-index: 1;
      outline: none !important;

      &:disabled + label {
        opacity: 0.65;
      }
    }
    input[type='checkbox']:focus + label {
      &::before {
        outline-offset: -2px;
        outline: none;
      }
    }
    input[type='checkbox']:checked + label {
      &::after {
        content: '\f00c';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
      }
    }
    input[type='checkbox']:disabled + label {
      &::before {
        background-color: $gray-100;
        cursor: not-allowed;
      }
    }
    input[type='checkbox']:checked + label {
      &::before {
        background-color: $primary;
        border-color: $primary;
      }
      &::after {
        color: $white;
      }
    }
  }
  .fixed-solution {
    .sticky-table-header {
      top: 70px !important;
    }
  }
}

@media (max-width: 768px) {
  .table-rep-plugin {
    .fixed-solution {
      .sticky-table-header {
        top: 145px !important;
      }
    }
  }
}

// data tables

.dataTable {
  thead {
    background-color: $navy !important;
    tr {
      th {
        &.sorting,
        &.sorting_desc,
        &.sorting_asc {
          &:before,
          &:after {
            position: absolute;
            bottom: 0.9em;
            display: block;
            opacity: 0.3;
          }
          &:before {
            right: 1em;
            content: '\2191';
          }
          &:after {
            right: 0.5em;
            content: '\2193';
          }
        }

        &.sorting_asc {
          &:before {
            opacity: 1;
          }
        }
        &.sorting_desc {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}

.dataTables_wrapper {
  .dataTables_length {
    label {
      font-weight: normal;
      text-align: left;
      white-space: nowrap;

      select {
        width: auto;
        display: inline-block;
      }
    }
  }
  .dataTables_filter {
    text-align: right;

    label {
      font-weight: normal;
      white-space: nowrap;
      text-align: left;
      input {
        margin-left: 0.5em;
        display: inline-block;
        width: auto;
      }
    }
  }

  .dataTables_paginate {
    ul {
      &.pagination {
        margin: 2px 0;
        white-space: nowrap;
        justify-content: flex-end;
      }
    }
  }
}
.ant-table-thead > tr > th {
  font-size: 16px !important;
}
.ant-table-small {
  .ant-table-thead {
    & > tr {
      & > th {
        background-color: $primary;
      }
    }
  }
}
.ant-table-thead {
  & > tr {
    & > th {
      color: $white;
      font-weight: 500;
      text-align: left;
      background: $white;
      border-bottom: 1px solid $primary;
      -webkit-transition: background 0.3s ease;
      transition: background 0.3s ease;
    }
  }
}
