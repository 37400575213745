.resume-preview {
  div#pdf-controls {
    display: none;
  }
}
.resume-preview {
  .jGqOvK {
    background: none;
  }
}
.resume-preview {
  #WACStatusBarContainer {
    visibility: hidden !important;
  }
}
div#WordViewerStatusBar {
  visibility: hidden !important;
}
span#buttonDockLeft {
  visibility: hidden !important;
}
div#WordViewerStatusBar {
  visibility: hidden;
}
span.cui-ctl-mediumlabel {
  visibility: hidden;
}
