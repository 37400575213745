.contentManipulation {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
}
.ant-tag-has-color {
  margin-bottom: 1px;
}

.ant-popover-content {
  max-width: 761px !important;
}
