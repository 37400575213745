.experience-location-selector .ant-select-selector {
  height: 35px;
}

.year-selector .ant-picker {
  width: 100%;
}

.logo-selector {
  .ant-upload-select {
    width: 100%;
    height: 95px;
    border-radius: 10px;
    background: white;
  }
  .ant-upload-list-picture .ant-upload-list-item-error,
  .ant-upload-list-picture-card .ant-upload-list-item-error {
    border-color: #c4c4c4;
    border-radius: 4px;
  }
  .ant-upload-list-picture-card-container {
    display: inline-block;
    width: 100%;
    height: 95px;
    margin: 0 8px 8px 0;
    vertical-align: top;
  }
}

.experience-drawer-fields {
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}
.marginSkills {
  margin-bottom: 0 !important;
}
.ant-drawer-close {
  display: none;
}

//picture
.hover-img .image-wrapper img,
.hover-img .view-icon,
.hover-img .delete-icon {
  opacity: 0.7;
}

.hover-img .image-wrapper {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
}

.hover-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hover-img img {
  opacity: 0.7;
}
.hover-img .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.hover-img .view-icon,
.hover-img .delete-icon {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.hover-img .view-icon {
  transform: translate(-100%, -50%);
  background-image: url('./icons/eye.svg');
  background-color: rgba(0, 0, 0, 0.7);
}

.hover-img .delete-icon {
  transform: translate(0, -50%);
  background-image: url('./icons/delete.svg');
  background-color: rgba(0, 0, 0, 0.7);
  margin-left: 5px;
}

.hover-img .view-icon,
.hover-img .delete-icon {
  display: block;
  cursor: pointer;
  background-color: transparent;
  opacity: 1;
}

// video buttons

.hover-video {
  position: relative;
  display: inline-block;
}
.hover-video .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.hover-video .image-wrapper {
  border-radius: 100%; /* Apply border radius to create rounded shape */
  overflow: hidden; /* Ensure the image stays within the rounded shape */
}

.hover-video img {
  max-width: 100%;
}

.hover-video {
  opacity: 0.7; /* Adjust the opacity value for the container */
}

.hover-video .video-view-icon,
.hover-video .video-delete-icon {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25px; /* Adjust icon size as needed */
  height: 25px; /* Adjust icon size as needed */
  background-size: cover;
  background-color: #00000099; /* Adjust the background color and opacity */
}

.hover-video .video-view-icon {
  transform: translate(-100%, -50%); /* Adjust positioning of the view-icon */
  background-image: url('./icons/video.svg');
  /* Replace with your icon's path */
  background-color: #00000099; /* Adjust the background color and opacity */
}

.hover-video .video-delete-icon {
  transform: translate(0, -50%); /* Adjust positioning of the delete-icon */
  background-image: url('./icons/delete.svg');
  background-color: #00000099;
  margin-left: 5px;
}

.hover-video .video-view-icon,
.hover-video .video-delete-icon {
  display: block;
  cursor: pointer;
  background-color: transparent;
}

// cover photo

.hover-coverPhoto {
  position: relative;
  display: inline-block;
}
.hover-coverPhoto .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 355px;
  height: 100%;
  margin-left: -22px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.hover-coverPhoto .image-wrapper {
  border-radius: 100%; /* Apply border radius to create rounded shape */
  overflow: hidden; /* Ensure the image stays within the rounded shape */
}

.hover-coverPhoto img {
  margin-left: -22px !important;
  width: 355px;
  height: 100px;
  object-fit: cover;
}

.hover-coverPhoto {
  opacity: 0.7; /* Adjust the opacity value for the container */
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the background color and opacity */
}

.hover-coverPhoto .view-icon,
.hover-coverPhoto .delete-icon {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px; /* Adjust icon size as needed */
  height: 30px; /* Adjust icon size as needed */
  background-size: cover;
}

.hover-coverPhoto .view-icon {
  transform: translate(-100%, -50%); /* Adjust positioning of the view-icon */
  background-image: url('./icons/eye.svg');
}

.hover-coverPhoto .delete-icon {
  transform: translate(0, -50%); /* Adjust positioning of the delete-icon */
  background-image: url('./icons/delete.svg');
  margin-left: 5px;
}

.hover-coverPhoto .view-icon,
.hover-coverPhoto .delete-icon {
  display: block;
  cursor: pointer;
}
.info-drawer {
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 0;
  }
  .pf-img {
    padding: 0px !important;
  }
  .pf-img {
    .ant-upload.ant-upload-drag {
      width: 100%;
    }
  }
}
.pf-video {
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 0px;
  }
}
.text-color {
  color: #008d8b;
}
.small-text-drawer-color {
  font-size: 12;
  font-weight: 400;
  color: #008d8b;
}
.large-text-drawer-color {
  font-size: 12;
  font-weight: 600;
  color: #008d8b;
}
// .pf-img {
//   .ant-upload-drag-container {
//     padding: 15px 0px 25px 0px;
//   }
// }
.Ant-D-Title {
  .ant-drawer-title {
    font-size: 18px !important;
    font-weight: 700 !important;
  }
}
// .image-overlay{
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5) ;
//   z-index: 1;
// }
.z-2 {
  z-index: 2;
}
