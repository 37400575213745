.Activity-table {
  .ant-table-tbody {
    .ant-table-row {
      font-size: 14px;
      font-weight: 400;
      color: rgba(73, 80, 87, 1);
    }
  }
}
.Activity-table {
  .ant-table-thead {
    .ant-table-column-title {
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
    }
  }
}
.btn-activity {
  img {
    width: 30px;
    height: 30px;
    // margin-top: 5px;
  }
}
ul.ant-pagination.ant-pagination-mini.ant-table-pagination.ant-table-pagination-right {
  background-color: #ffffff;
  padding: 33px !important;
}
.ant-table-pagination.ant-pagination {
  margin: 0 !important;
}
// .btn-newcd {
//   background-image: url('./statusIcon/newcd.svg');
//   background-color: transparent;
//   height: 40px;
//   width: 40px;
//   cursor: pointer;
//   background-size: cover;
//   border: none;
//   text-align: center;
//   text-decoration: none;
//   justify-content: center;
//   margin-top: 5px;
// }
// .btn-newcd:hover {
//   background-image: url('./statusIcon/hovercd.png');
//   height: 40px;
//   width: 40px;
//   cursor: pointer;
// }
// //btn like
// .btn-like {
//   background-image: url('./statusIcon/like.svg');
//   background-color: transparent;
//   height: 32px;
//   width: 32px;
//   cursor: pointer;
//   background-size: cover;
//   border: none;
//   text-align: center;
//   text-decoration: none;
//   display: inline-block;
// }
// .btn-like:hover {
//   background-image: url('./statusIcon/like.png');
//   height: 32px;
//   width: 32px;
//   cursor: pointer;
// }
// //btn dislike
// .btn-dislike {
//   background-image: url('./statusIcon/dislike.svg');
//   background-color: transparent;
//   height: 32px;
//   width: 32px;
//   cursor: pointer;
//   background-size: cover;
//   border: none;
//   text-align: center;
//   text-decoration: none;
//   display: inline-block;
// }
// .btn-dislike:hover {
//   background-image: url('./statusIcon/dislike.png');
//   height: 32px;
//   width: 32px;
//   cursor: pointer;
// }

// // hire btn
// .btn-hire {
//   background-image: url('./statusIcon/hire.svg');
//   background-color: transparent;
//   height: 40px;
//   width: 40px;
//   cursor: pointer;
//   background-size: cover;
//   border: none;
//   text-align: center;
//   text-decoration: none;
//   display: inline-block;
// }
// .btn-hire:hover {
//   background-image: url('./statusIcon/hire.png');
//   height: 40px;
//   width: 40px;
//   cursor: pointer;
// }
// //btn chat
// .btn-chat {
//   background-image: url('./statusIcon/chat.svg');
//   background-color: transparent;
//   height: 32px;
//   width: 32px;
//   cursor: pointer;
//   background-size: cover;
//   border: none;
//   text-align: center;
//   text-decoration: none;
//   display: inline-block;
// }
// .btn-chat:hover {
//   background-image: url('./statusIcon/chat.png');
//   height: 32px;
//   width: 32px;
//   cursor: pointer;
// }
// // btn selected

// .btn-select {
//   background-image: url('./statusIcon/selected.svg');
//   background-color: transparent;
//   height: 40px;
//   width: 40px;
//   cursor: pointer;
//   background-size: cover;
//   border: none;
//   text-align: center;
//   text-decoration: none;
//   display: inline-block;
// }
// .btn-select:hover {
//   background-image: url('./statusIcon/selected.png');
//   height: 40px;
//   width: 40px;
//   cursor: pointer;
// }
// btn reject

// .btn-reject {
//   background-image: url('./statusIcon/reject.svg');
//   background-color: transparent;
//   height: 32px;
//   width: 32px;
//   cursor: pointer;
//   background-size: cover;
//   border: none;
//   text-align: center;
//   text-decoration: none;
//   display: inline-block;
// }
// .btn-reject:hover {
//   background-image: url('./statusIcon/reject.png');
//   height: 32px;
//   width: 32px;
//   cursor: pointer;
// }
.ellipse-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 90%;
}
.ant-table-container table > thead > tr:first-child th:first-child:hover {
  background-color: #293847;
}
