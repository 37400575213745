.directory-card-job > .ant-card-body {
  padding: 0 !important;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.rounded-circle {
  border-radius: 50% !important;
}

.set-job-detail-image {
  width: 80px !important;
  height: 80px !important;
  margin-right: 32px;
}
.set-job-title {
  display: flex;
  align-items: flex-end;
  padding: 10px;
}

.linkedIn-Candidate {
  width: 120px !important;
  height: 120px !important;
}

.tabs-parent {
  // Styles for .my-styles

  .ant-tabs-top > .ant-tabs-nav {
    // Styles for any .my-child-div nested within .my-styles
    min-width: 100% !important;
    border-bottom: 2px;
    border-color: #dadada !important;
  }
}

// .preselected-candidates-table-parent{
.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  // line-height: 13px !important;
}
// }

.ant-table.ant-table-small .ant-table-thead > tr > th:hover,
.ant-table-thead
  th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
  background-color: #2b3a4a !important;
}

td.ant-table-cell.ant-table-column-sort {
  background: unset !important;
}

.ant-table-pagination-right {
  .ant-pagination.ant-pagination-mini
    .ant-pagination-options-size-changer
    .ant-select-selector {
    min-height: 30px !important;
    font-size: 18px !important;
  }
}

.ant-table-pagination-right {
  .ant-pagination.ant-pagination-mini
    .ant-pagination-options-size-changer
    .ant-select-selector {
    min-height: 30px !important;
    padding-top: 2px !important;
    margin-top: -2px !important;
  }
}

.ant-table-pagination-right {
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector::after,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-item,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    // padding-top: 1px !important ;
    font-size: 16px !important;
    // margin-top: 3px !important;
    height: 60px;
  }
}
