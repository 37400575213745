.reset-btn {
  background-color: #bc9623;
  font-size: 14px !important;
  position: absolute;
  right: 0;
  border: none;
  margin-top: 0px !important;
  &:hover {
    background-color: #bc9623 !important;
    border: none;
  }
  &:active {
    background-color: #bc9623 !important;
    border: none;
  }
  &::after {
    background-color: #bc9623 !important;
    border: none;
  }
  &:checked {
    background-color: #bc9623 !important;
    border: none;
  }
  &:focus {
    background-color: #bc9623 !important;
    border: none;
  }
}
.btn-w-full {
  width: 100%;
}
.btn-no-brdr {
  border: none;
}
.weight-500 {
  font-weight: 500;
}
.heading-color {
  color: var(--bold-text, rgba(0, 0, 0, 0.85)) !important;
}
.recover-success-heading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 20px 20px 20px;
  border-radius: 8px;
  background: rgba(65, 131, 148, 0.2);
  // gap: 10px;
  // align-self: stretch;
}
.recover-success-heading {
  color: #418394;
  text-align: center;
  // font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.resend-code-link {
  text-align: end;
  padding-top: 10px;
  cursor: pointer;
  color: #012653;
  font-size: 14px;
  text-decoration: underline;
  font-weight: 500;
  line-height: 21px;
}
.ant-form-vertical .ant-form-item-label {
  padding: 0px;
}
