.color-benchMark {
  color: '#2e4a79' !important;
  position: relative;
}
.headerMargin {
  margin-left: 0 !important ;
}
.shadow-bench {
  filter: drop-shadow(0px 5px 1px rgba(0, 0, 0, 0.14));
  background: white;
}
/* Hide element in browser view */
.hide-in-browser {
  display: none;
}
// .hide-in-print{
//   display: block;
// }
.single_cdp_parent {
  padding-top: 80px;
  padding-bottom: 80px;
}

/* Show element in print view */
@media print {
  .hide-in-browser {
    display: block;
  }
}
@media print {
  .single_cdp_parent {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
@media print {
  .hide-in-print {
    display: none !important;
  }
}
@media print {
  .side-menu {
    display: none !important;
  }
}
@media print {
  .topbar {
    display: none !important;
  }
}
@media print {
  .margin-on-print {
    margin-top: -70px !important;
    // margin-left: -70px !important;
  }
}
@media print {
  .content-page {
    margin-left: 0px !important;
    // margin-left: -70px !important;
  }
}
