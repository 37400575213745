.ant-select-selector {
  min-height: 35px;
  border-radius: 1.25rem !important;
}
.ant-select-selection-item {
  background: #dadada !important;
}
.ant-modal-content {
  // border-radius: 0px !important;
}

// .ant-modal-body .ant-btn{
//     // color:white !important;
// }
