.title {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: rgba(46, 74, 121, 1) !important;
}
.page-title-box {
  span {
    margin-left: 8px;
  }
}
