/* ==============
  Progress
===================*/

.progress {
  height: 10px;
}

.progress-bar {
  background-color: $primary;
}

.progress-bar {
  &.light-blue {
    background-color: #7286ff !important;
  }
  &.light-red {
    background-color: #f98497 !important;
  }
  &.light-teal {
    background-color: #80cbc4 !important;
  }
  &.lighter-blue {
    background-color: #90caf9;
  }
}

.progress-round {
  width: 150px;
  height: 150px;
  background: none;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 6px solid #eee;
    position: absolute;
    top: 0;
    left: 0;
  }

  > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .progress-left {
    left: 0;

    .progress-bar {
      left: 100%;
      border-top-right-radius: 80px;
      border-bottom-right-radius: 80px;
      border-left: 0;
      -webkit-transform-origin: center left;
      transform-origin: center left;
    }
  }

  .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 6px;
    border-style: solid;
    position: absolute;
    top: 0;
  }

  .progress-right {
    right: 0;

    .progress-bar {
      left: -100%;
      border-top-left-radius: 80px;
      border-bottom-left-radius: 80px;
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
    }
    .progress-value {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
