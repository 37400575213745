.login-btn {
  background-color: #bc9623;
  position: absolute;
  right: 0;
  font-size: 14px !important;
  border: none;
  margin-top: 0px !important;
  height: 30px;
  padding-top: 4px;

  &:hover {
    background-color: #bc9623 !important;
    border: none;
  }
  &:active {
    background-color: #bc9623 !important;
    border: none;
  }
  &::after {
    background-color: #bc9623 !important;
    border: none;
  }
  &:checked {
    background-color: #bc9623 !important;
    border: none;
  }
  &:focus {
    background-color: #bc9623 !important;
    border: none;
  }
}
.login-checkbox {
  color: #2e4a79;
}
.card-alignment {
  width: 85%;
  margin: auto;
}
.login-link {
  position: absolute;
  right: 0;
  color: #012653;
}
.login-link-span {
  color: #012653;
  font-size: 14px;
  text-decoration: underline;
  font-weight: 500;
  line-height: 21px;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #012653;
  border-color: #012653;
}
.login-wrapper {
  .ant-form-item-label {
    padding: 0px;
  }
}
