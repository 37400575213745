.search-type .ant-select-selection-item {
  margin-top: 0px !important;
}
.ant-form-item-label > label::after {
  content: '';
}

.ant-row .ant-form-item {
  display: block;
}

.hide-label {
  label {
    visibility: hidden;
  }
}

.filter-search {
  border-radius: 20px 0px 0px 20px;
  border-right: unset;
}

.tiltle-select {
  .ant-select-selector {
    border-radius: 0px 5px 5px 0px !important;
    height: 100% !important;
    padding: 0.2rem 0.75rem !important;
    border-left: unset !important;
  }
}

.filter-btn {
  border-radius: 8px !important;
  height: 50;
  width: 100;
  background-color: '#E6E6E6';
}

.filter-btn:hover,
.filter-btn:active,
.filter-btn:focus {
  border-radius: 8px !important;
  border-color: inherit;
  color: inherit;
}

.filter-btn-active,
.filter-btn-active:hover,
.filter-btn-active:active,
.filter-btn-active:focus {
  border-radius: 8px !important;
  background-color: #5b626b;
  color: white !important;

  path {
    fill: white;
  }
  .action {
    transform: rotate(90deg);
  }
}

.source-select .ant-select-selector {
  border-radius: 20px !important;
  padding: 0.255rem 0.75rem !important;
  height: 100% !important;
}

.search-button {
  background-color: #2e4a79;
  color: #fff;
  padding: 0.2rem 1.5rem;
  margin-left: 5px;
  border-radius: 20px;
  height: 38px;
}

.ant-select-dropdown {
  width: 100px !important;
}

.search-type .ant-select-selector {
  padding: 3px 10px !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

label {
  margin-bottom: 10px;
  display: block;
}

.filters-wrapper {
  margin-top: 10px;
}

.candidate-list-wrapper {
  display: flex;
  flex-direction: column;
}

// .candidate-list-wrapper div:first-child {
//   text-align: right;
// }

.edit-drawer .ant-drawer-header-title {
  flex-direction: row-reverse;
}

.edit-drawer .ant-drawer-header-title button {
  margin: 0;
}

.row-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2e4a7a;
  border-color: #2e4a7a;
}

.top-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2e4a7a;
  border-color: #fff;
}
.container-Search {
  .ant-select.ant-select-in-form-item {
    width: 110px !important;
    text-align: center;
  }
}

.source-select1 .ant-select-selection-placeholder {
  margin-top: 1px;
}

.source-select1 .ant-select-selection-item {
  // margin-top: 5px;
}

.edit-tag-model-parent {
  .ant-modal-title {
    margin-top: -14px !important;
  }
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  // line-height: 22px !important;
}

.ant-select-selection-search {
  margin-inline-start: 0px !important;
}
