// @import "../../../../assets/scss/variables";

.ant-modal-content {
  border-radius: 10px !important;
}

.ant-modal-footer {
  display: flex;
  justify-content: space-between;
  border-top: none !important;
  padding: 6px 18px 22px 18px;
}

.ant-modal-header {
  border-bottom: none !important;
  padding: 18px;
  border-radius: 20px;
}
.ant-modal-title {
  margin-top: 20px !important;
}

.ant-modal-body {
  display: flex !important;
  flex-direction: column;
  margin: 0px !important;
  padding: 16px;
  margin-top: -10px !important;
  width: auto !important;
}

.ant-modal-body .ant-btn {
  // background-color: #f5f7f9 !important;
  color: #000000d9 !important;
  width: auto;
  height: auto;
  border-radius: 8px;
  border: 'none';
  margin-right: 8px;
}
.ant-modal-body .ant-btn.Experience {
  background-color: #990000cc !important;
  color: #ffffff !important;
}

.ant-modal-body .ant-btn.Traits {
  background-color: #2e4a79e5 !important;
  color: #ffffff !important;
}
.ant-modal-body .ant-btn.Drivers {
  background-color: #008d8b !important;
  color: #ffffff !important;
}

.view-switches {
  margin-bottom: 16px;
  margin-left: 0px;
}

.table-row {
  width: 100%;
  background: #f5f7f9;
  margin: 0px !important;
  padding: 12px !important;
}

.radio-group-with-gap .ant-radio-button-wrapper {
  margin-left: 18px;
  border: none;
  margin-top: 8px;
  border-radius: 8px !important;
  width: 72px !important;
  height: 72px !important;
  box-shadow: none;
}
.Experiences .ant-radio-button-wrapper {
  background-color: #990000cc;
}
.Traits .ant-radio-button-wrapper {
  background-color: #2e4a79e5;
}
.Drivers .ant-radio-button-wrapper {
  background-color: #008d8b;
}

.radio-group-with-gap .ant-radio-button-wrapper-checked {
  background-color: #bc9623cc !important;
  border: none !important;
  box-shadow: none !important;
}
.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: #ff787500 !important;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #ff787500 !important;
  margin-left: 0px;
}

/* Targeting the scrollbar */
.scroll-bar::-webkit-scrollbar {
  width: 8px; /* Reducing the width */
}

/* Track (background of the scrollbar) */
.scroll-bar::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2); /* Glass-like background */
  border-radius: 10px;
}

/* Handle (the draggable part) */
.scroll-bar::-webkit-scrollbar-thumb {
  background: rgba(188, 185, 185, 0.5); /* Glass-like handle */
  border-radius: 10px;
  backdrop-filter: blur(5px); /* Adds the glass effect */
}

/* Handle on hover */
.scroll-bar::-webkit-scrollbar-thumb:hover {
  background: rgba(102, 101, 101, 0.7); /* Slightly more opaque on hover */
  cursor: pointer;
}

/* Applying similar styling for Firefox */
// scrollbar-width: thin; /* Reducing width */
// scrollbar-color: rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0.2); /* Thumb and track colors */

.assessment-modal {
  .ant-modal-close {
    margin: 14px !important;
  }

  .ant-modal-close > .ant-modal-close-x {
  }
}
.close-button-assessment {
  margin: 14px !important;
}

.assessmeent-modal .ant-modal-content {
  border-radius: 10px !important;
}

.assessment-tab-bg {
  background-color: #f5f7f9;
  border-color: white;
}
.assessment-tab-bg:hover {
  background-color: #f5f7f9;
  border-color: white;
}
