.edit-tag-modal .ant-modal-body {
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
}

.custom-footer-modal {
  border-top: solid;
  border-width: 1px;
  border-color: black;
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
}

.custom-body-modal {
  padding-right: 15px;
  padding-left: 15px;
}

.tags-border .ant-select-selector {
  border-radius: 5px !important;
}

.list-item .ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
  padding-left: 10px;
  font-weight: 400 !important;
  font-family: 'Poppins';
}

.list-item:hover {
  background-color: #bae7ff;
}

.list-item .delete-btn,
.list-item .edit-btn {
  display: none;
}

.list-item:hover .delete-btn,
.list-item:hover .edit-btn {
  display: block;
}

.empty-table table {
  width: 100% !important;
  table-layout: auto !important;
}

.empty-table .ant-table-expanded-row-fixed {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}
.ant-drawer-header {
  border-color: black !important;
}
.edit-tag-modal {
  border-radius: 0px !important;
}
.ant-tabs-nav-wrap,
.ant-tabs-nav {
  max-width: 90%;
}
.crawlies-table .ant-table-thead > tr > th {
  font-size: 14px !important;
}
