thead {
  height: 80px;
}
.ant-tabs > .ant-tabs-nav {
  min-width: 100%;
  border-color: #dadada;
}

.ant-table.ant-table-small .ant-table-thead > tr > th:hover,
.ant-table-thead
  th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
  background-color: #2b3a4a !important;
}
