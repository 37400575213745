.chat__timestamp {
  margin-left: 10px;
  font-size: xx-small;
}

.chat__footer {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-top: -4px;
}

.chat__footer > form {
  display: flex;
  flex: 1;
}

.chat__footer > form > input {
  flex: 1;
  outline: none;

  padding: 8px;
  border-radius: 30px;
}

.chat__footer > .MuiSvgIcon-root {
  padding: 10px;
  color: grey;
}

.chat-body {
  padding: 0;
}

.comments-body {
  border-radius: 15px !important;

  .ant-card-body {
    padding: 30px 0px 30px 0px;
  }

  h4 {
    padding: 0px 24px 12px;
  }

  .editorCol {
    padding-top: 10px;
    padding-bottom: 0px;
    .mce-content-body {
      margin: 10px 10px;
      border: 1px solid #2e4a79;
      border-radius: 10px;
      min-height: 80px;
      max-height: auto;
      padding: 6px 6px;

      p {
        margin-bottom: 0;
      }
    }
    .mce-content-body::before {
      padding: 0px 6px;
    }
  }

  .send-btn {
    background: #bc9623;
    border-radius: 8px;
    margin-left: auto;
    border: none;
  }
}

.inbox-wid {
  background: #f9f9f9;
  .inbox-item {
    .chat-body {
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
}

.msg-style {
  padding: 10px;
  width: 100%;
  border: none;
}

.chat-body .msg-sent {
  text-align: left;
}

.msg-direction-left {
  display: flex;
  min-height: 50px;
  max-height: auto;
  height: fit-content;
  // margin-bottom: -20px;
  // cursor: pointer;
}

.sender-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .msg-info {
    margin-bottom: 10px;
    font-size: 10;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-family: Poppins;
    .msg-info-name {
      margin-left: 5px;
      color: #012653;
    }
  }

  p {
    color: #012653 !important;
  }
}

.msg-text-content {
  color: #2e4a79;
  min-width: 80px;
  max-width: 100%;
  width: fit-content;
  min-height: 35px;
  padding: 4px 10px 2px 10px;
  margin-top: 10px;
  cursor: pointer;
}

.msg-left-text-color {
  background-color: #ffffff;
}

.talkbubble-left {
  border-radius: 0px 15px 15px 15px;
  position: relative;
  margin-bottom: 0;
}

.msg-me {
  background-color: rgba(192, 228, 196, 0.25);
}

.benchmark .ant-radio-button-wrapper {
  display: block;
  width: 240px;
  margin: 5px 0px;
  text-align: center;
  border-radius: 5px;
  height: 40px;
  padding: 4px;
}

.ant-radio-button-wrapper-checked {
  background-color: #2e4a79 !important;
  border-color: #2e4a79 !important;
}

.pr-info textarea.ant-input {
  min-height: 135px !important;
}

.pr-info-btn {
  float: right;
  margin-top: 10px;
  border-radius: 8px;
  padding: 4px 22px;
  background: #bc9623;
  color: #fff;
  border-color: #bc9623;
  cursor: pointer;
}

.inbox-item-author {
  margin: 0 !important;
}

.comments-body {
  .ant-avatar {
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: 10px;
  }
}
.headerSection {
  margin-right: -125px !important;
}
.img-skeleton {
  .ant-skeleton-image {
    border-radius: 50%;
    width: 145px;
    height: 145px;
    margin-top: -60px;
  }
}
.bg-color {
  background-color: white !important;
  border-radius: 10;
  padding: 29px 35px 29px 35px;
}
.bg-none {
  display: '';
  background-color: transparent !important;
  margin-top: 70px;
  justify-content: center;
}
.align_Content {
  display: flex;
  align-items: start;
  justify-content: center;
}

.min-h-28px .ant-select-selector {
  min-height: 28px !important;
}

.img-contain img {
  object-fit: contain !important;
}
